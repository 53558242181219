/* Set up using Vue 3 */
import { createApp } from "vue";
import App from "./App.vue";
import { registerLicense } from "@syncfusion/ej2-base";
import { VueFire, VueFireAuth } from "vuefire";
import { app as firebaseApp } from "./firebase";
import posthogPlugin from "./plugins/posthog";

import "./assets/main.css";
import "./assets/printStyles.scss";

import router from "./router";
import { initIcons } from "./icons";

registerLicense(
  "ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5UdEViWH1ccXRdTmRU"
);

export const app = createApp(App);

initIcons();

app.use(router);
app.use(posthogPlugin);
app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
});

app.mount("#app");
