<script lang="ts" setup>
import { Toaster as Sonner, type ToasterProps } from "vue-sonner";

const props = defineProps<ToasterProps>();
</script>

<template>
  <Sonner
    class="toaster group fixed-width"
    v-bind="props"
    :toast-options="{
      classes: {
        toast: 'group toast rounded-md group-[.toaster]:shadow-lg',
        description: 'group-[.toast]:text-muted-foreground',
        actionButton:
          'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
        cancelButton:
          'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
      },
    }" />
</template>
<style scoped>
.fixed-width {
  width: 300px; /* Adjust the width as needed */
}
</style>
