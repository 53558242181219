<template>
  <router-view></router-view>
  <Toaster></Toaster>
  <Sonner position="bottom-left"></Sonner>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCurrentUser } from "vuefire";
import posthog from "posthog-js";
import Toaster from "@/components/ui/toast/Toaster.vue";
import { Toaster as Sonner } from "@/components/ui/sonner";

// NOTE: Please don't put any global logic here which can leave in separate files.
// Keep this file lean.

const user = useCurrentUser();
const router = useRouter();
const route = useRoute();

watch(user, async (currentUser, previousUser) => {
  if (currentUser) {
    posthog.identify(currentUser.email ?? currentUser.uid, {
      email: currentUser.email,
    });
  } else {
    console.log("User not identified");
    posthog.startSessionRecording();
  }
  if (!currentUser && previousUser && route.meta.requiresAuth) {
    router.push("/login");
  }
});
</script>
